@media (max-width: 600px) {
    .navbar-brand {
        font-size: 12px !important;
    }

    .anim {
        color: red !important;
        font-size: 12px !important;
        display: none;
    }

    .bottom {
        font-size: 12px;
    }

}